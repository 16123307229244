import React from "react";
import Layout from "./layout";
import Seo from "./seo";

const AcquistaReportLayout = ({ children }) => {
  return (
    <Layout className="report">
      <Seo
        title={`Acquista Report IIC`}
        description={`Compila il form per completare l'acquisto del report`}
      />
      <div className="box-ticket-wrap">
        <div className="box-ticket-wrap__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 text-center">
                <div className="box-ticket-wrap__subject text-uppercase">Acquista report IIC</div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="box-ticket box-ticket--active">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AcquistaReportLayout;
