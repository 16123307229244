import React, { useEffect } from "react";
import { Link } from "../components/link";

const AcquistaThanks = ({ children }) => {
  useEffect(() => {
    sessionStorage.removeItem("iid-form-acquista-report-iic");
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="text-center">{children}</div>
        </div>
      </div>
      <div className="form__navigation">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <Link to="/" className="btn-link btn-link--small">
                <span className="btn-link__circle"></span>
                <span className="btn-link__text">
                  Torna alla <span className="d-block bold">homepage</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcquistaThanks;
